// Network chain ids
export enum CHAIN_ID {
  // BSC = 56,
  // BSC_TESTNET = 97,
  MAINNET = 1,
  // ROPSTEN = 3,
  // RINKEBY = 4,
}

// Network labels
export const NETWORK_LABEL = {
  // [CHAIN_ID.BSC]: 'BSC',
  // [CHAIN_ID.BSC_TESTNET]: 'BSC Testnet',
  [CHAIN_ID.MAINNET]: 'Ethereum',
  // [CHAIN_ID.ROPSTEN]: 'Ropsten',
  // [CHAIN_ID.RINKEBY]: 'Rinkeby',
}

// Network RPC nodes
export const NETWORK_RPC = {
  // [CHAIN_ID.BSC]: [
  //   'https://bsc-dataseed.binance.org/',
  //   'https://bsc-dataseed1.ninicoin.io',
  //   'https://bsc-dataseed1.defibit.io',
  // ],
  // [CHAIN_ID.BSC_TESTNET]: ['https://data-seed-prebsc-2-s3.binance.org:8545/'],
  [CHAIN_ID.MAINNET]: [`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`],
  // [CHAIN_ID.ROPSTEN]: [`https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`],
  // [CHAIN_ID.RINKEBY]: [`https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`],
}

// Network block explorers
export const BLOCK_EXPLORER = {
  // [CHAIN_ID.BSC]: 'https://bscscan.com',
  // [CHAIN_ID.BSC_TESTNET]: 'https://testnet.bscscan.com/',
  [CHAIN_ID.MAINNET]: 'https://etherscan.io/',
  // [CHAIN_ID.ROPSTEN]: 'https://ropsten.etherscan.io/',
  // [CHAIN_ID.RINKEBY]: 'https://rinkeby.etherscan.io/',
}

// For switch network configuration
export const CHAIN_PARAMS = {
  // [CHAIN_ID.BSC]: {
  //   chainId: '0x38',
  //   chainName: 'Binance Smart Chain',
  //   nativeCurrency: {
  //     name: 'bnb',
  //     symbol: 'BNB',
  //     decimals: 18,
  //   },
  //   rpcUrls: NETWORK_RPC[CHAIN_ID.BSC],
  //   blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.BSC]],
  // },
  // [CHAIN_ID.BSC_TESTNET]: {
  //   chainId: '0x61',
  //   chainName: 'Binance Smart Chain Testnet',
  //   nativeCurrency: {
  //     name: 'bnb',
  //     symbol: 'BNB',
  //     decimals: 18,
  //   },
  //   rpcUrls: NETWORK_RPC[CHAIN_ID.BSC_TESTNET],
  //   blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.BSC_TESTNET]],
  // },
  [CHAIN_ID.MAINNET]: {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'eth',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.MAINNET],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.MAINNET]],
  },
  // [CHAIN_ID.ROPSTEN]: {
  //   chainId: '0x3',
  //   chainName: 'Ropsten Test Network',
  //   nativeCurrency: {
  //     name: 'eth',
  //     symbol: 'ETH',
  //     decimals: 18,
  //   },
  //   rpcUrls: NETWORK_RPC[CHAIN_ID.ROPSTEN],
  //   blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.ROPSTEN]],
  // },
  // [CHAIN_ID.RINKEBY]: {
  //   chainId: '0x4',
  //   chainName: 'Rinkeby Test Network',
  //   nativeCurrency: {
  //     name: 'eth',
  //     symbol: 'ETH',
  //     decimals: 18,
  //   },
  //   rpcUrls: NETWORK_RPC[CHAIN_ID.RINKEBY],
  //   blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.RINKEBY]],
  // }
}

interface BaseChainInfo {
  readonly label: string
  readonly logoUrl: string
  readonly explorer: string
}

export type ChainInfoMap = { readonly [chainId: number]: BaseChainInfo }

export const CHAIN_INFO: ChainInfoMap = {
  // [CHAIN_ID.BSC]: {
  //   label: NETWORK_LABEL[CHAIN_ID.BSC],
  //   logoUrl: '/images/bnb-logo.svg',
  //   explorer: BLOCK_EXPLORER[CHAIN_ID.BSC],
  // },
  // [CHAIN_ID.BSC_TESTNET]: {
  //   label: NETWORK_LABEL[CHAIN_ID.BSC_TESTNET],
  //   logoUrl: '/images/bnb-logo.svg',
  //   explorer: BLOCK_EXPLORER[CHAIN_ID.BSC_TESTNET],
  // },
  [CHAIN_ID.MAINNET]: {
    label: NETWORK_LABEL[CHAIN_ID.MAINNET],
    logoUrl: '/images/ethereum-logo.png',
    explorer: BLOCK_EXPLORER[CHAIN_ID.MAINNET],
  },
  // [CHAIN_ID.ROPSTEN]: {
  //   label: NETWORK_LABEL[CHAIN_ID.ROPSTEN],
  //   logoUrl: '/images/ethereum-logo.png',
  //   explorer: BLOCK_EXPLORER[CHAIN_ID.ROPSTEN],
  // },
  // [CHAIN_ID.RINKEBY]: {
  //   label: NETWORK_LABEL[CHAIN_ID.RINKEBY],
  //   logoUrl: '/images/ethereum-logo.png',
  //   explorer: BLOCK_EXPLORER[CHAIN_ID.RINKEBY],
  // }
}
