import { ethers } from 'ethers'

// Addresses
import {
  getMulticallAddress,
  getNftCashierAddress,
  getNftAddress
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import nftCashierAbi from 'config/abi/nftCashier.json'
import nftAbi from 'config/abi/nft.json'
import getProvider from './providers'

const getContract = (abi: any, address: string, chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const provider = getProvider(chainId)
  const signerOrProvider = signer ?? provider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, chainId, signer)
}

export const getErc721Contract = (address: string, chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, chainId, signer)
}

export const getNftCashierContract = (chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftCashierAbi, getNftCashierAddress(chainId), chainId, signer)
}

export const getNftContract = (chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftAbi, getNftAddress(chainId), chainId, signer)
}

export const getMulticallContract = (chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(chainId), chainId, signer)
}
