import styled from 'styled-components'
import { Text, Button } from '@pancakeswap/uikit'


export const GeneralText = styled(Text)
  <{ color: string, fontSize: string, weight: number, fontFamily: string, fontStyle?: string, textTransform?: string }>`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-style: ${({ fontStyle }) => fontStyle};
  font-weight: ${({ weight }) => weight};
  font-family: ${({ fontFamily }) => fontFamily};
  text-transform: ${({ textTransform }) => textTransform};
  white-space: normal;
`

export const ButtonStyled = styled(Button)
  <{ bgColor: string, btnShadow: string, width: string, height: string }>`
  background: ${({ bgColor }) => bgColor};
  border: 2px solid #000000;
  box-shadow: ${({ btnShadow }) => btnShadow};
  border-radius: 16px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`