import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address, chainId: number): string => {
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getNftCashierAddress = (chainId: number) => {
  return getAddress(addresses.nftCashierContr, chainId)
}

export const getNftAddress = (chainId: number) => {
  return getAddress(addresses.nftContr, chainId)
}

export const getMulticallAddress = (chainId: number) => {
  return getAddress(addresses.multiCall, chainId)
}
