import React, {
  useState
} from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useWeb3React } from '@web3-react/core'
import {
  LinkExternal
} from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { languageList } from 'config/localization/languages'
import LangSelector from 'components/LangSelector'
import { ButtonStyled, GeneralText } from 'components/GlobalStyle'

const StyledI = styled.i`
  color: var(--whiteColor);
`

const StyledMobileImg = styled.img`
  @media only screen and (max-width: 767px) {
    display: none;
  }
`

const StyleLinkExternal = styled(LinkExternal)`
  svg {
    display: none;
  }
`

const Menu = () => {
  const { account } = useWeb3React()

  const [showMenu, setshowMenu] = useState(false)

  const toggleMenu = () => {
    setshowMenu(!showMenu)
  }

  const { setLanguage, currentLanguage } = useTranslation()

  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split("/")
  const currLocation = splitLocation[1]

  return (
    <>
      <div id='navbar' className='navbar-area'>
        <div className='raimo-responsive-nav'>
          <div className='container'>
            <div className='raimo-responsive-menu'>
              <div role='presentation' onClick={() => toggleMenu()} className='hamburger-menu'>
                {showMenu ? (
                  <StyledI className='bx bx-x' />
                ) : (
                  <StyledI className='bx bx-menu' />
                )}
              </div>
              <div className='logo'>
                <a href='/home'>
                  <StyledMobileImg src='/logo.png' alt='logo' style={{ maxWidth: '144px' }} />
                </a>
              </div>
              <div className='responsive-others-option'>
                <div className='d-flex align-items-center'>
                  <div className='option-item'>
                    <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                  </div>
                  <div className='option-item'>
                    <StyleLinkExternal href="https://dapp.goliv.io" style={{ textDecoration: 'none' }} external={false}>
                      <ButtonStyled
                        bgColor="#F5F7FB"
                        btnShadow="0px 6px 0px #000000"
                        width="100%"
                        height="48px"
                      >
                        <GeneralText color="var(--blackColor)" fontSize="1em" fontFamily="var(--fontFamily2)" weight={700} lineHeight={1.1}>
                          <FormattedMessage
                            id='launch-marketplace'
                            defaultMessage="Launch Marketplace"
                          />
                        </GeneralText>
                      </ButtonStyled>
                    </StyleLinkExternal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className={showMenu ? 'show navbar navbar-expand-md navbar-light' : 'navbar navbar-expand-md navbar-light hide-menu'}>
          <div className='container'>
            <a href='/home' className='navbar-brand'>
              <img src='/logo.png' alt='logo' style={{ maxWidth: '144px' }} />
            </a>
            <div className='collapse-menu navbar-collapse mean-menu' role="presentation" onClick={() => toggleMenu()}>
              <ul className='navbar-nav'>
                <li className='nav-item'>
                  <Link to="/home" className={currLocation === "home" ? "active" : ""}>
                    <FormattedMessage
                      id='home'
                      defaultMessage="Home"
                    />
                  </Link>
                </li>
                {/* <li className='nav-item'>
                  <Link to="/mint" className={currLocation === "mint" ? "active" : ""}>
                    <FormattedMessage
                      id='mint'
                      defaultMessage="Mint"
                    />
                  </Link>
                </li> */}
                <li className='nav-item'>
                  <a href="https://docs.goliv.io/" target="_BLANK" rel="noopener noreferrer">
                    <FormattedMessage
                      id='litepaper'
                      defaultMessage="Litepaper"
                    />
                  </a>
                </li>
                <li className='nav-item'>
                  <Link to="/roadmap" className={currLocation === "roadmap" ? "active" : ""}>
                    <FormattedMessage
                      id='roadmap'
                      defaultMessage="Roadmap"
                    />
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to="/faq" className={currLocation === "faq" ? "active" : ""}>
                    <FormattedMessage
                      id='faq'
                      defaultMessage="FAQ"
                    />
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to="/about-us" className={currLocation === "about-us" ? "active" : ""}>
                    <FormattedMessage
                      id='about'
                      defaultMessage="About Us"
                    />
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to="/privacy-policy" className={currLocation === "privacy-policy" ? "active" : ""}>
                    <FormattedMessage
                      id='privacy-policy'
                      defaultMessage="Privacy Policy"
                    />
                  </Link>
                </li>
                {currLocation === "reset-password" ?
                  <li className='nav-item'>
                    <Link to="/reset-password" className={currLocation === "reset-password" ? "active" : ""}>
                      <FormattedMessage
                        id='reset-password'
                        defaultMessage="Reset Password"
                      />
                    </Link>
                  </li>
                  : null}
              </ul>
              <div className='others-option'>
                <div className='d-flex align-items-center'>
                  <div className='option-item'>
                    <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                  </div>
                  <div className='option-item'>
                    <StyleLinkExternal href="https://dapp.goliv.io" style={{ textDecoration: 'none' }} external={false}>
                      <ButtonStyled
                        bgColor="#F5F7FB"
                        btnShadow="0px 6px 0px #000000"
                        width="100%"
                        height="48px"
                      >
                        <GeneralText color="var(--blackColor)" fontSize="1em" fontFamily="var(--fontFamily2)" weight={700} lineHeight={1.1}>
                          <FormattedMessage
                            id='launch-marketplace'
                            defaultMessage="Launch Marketplace"
                          />
                        </GeneralText>
                      </ButtonStyled>
                    </StyleLinkExternal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Menu
