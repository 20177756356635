import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useUpdateNetwork } from 'state/network/hooks'
import { usePollBlockNumber } from 'state/block/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import Menu from './components/Menu'
// import Footer from './components/Footer'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'
import 'react-vertical-timeline-component/style.min.css'

// Route-based code splitting
const NotFound = lazy(() => import('./views/NotFound'))
const Home = lazy(() => import('./views/Home'))
const Mint = lazy(() => import('./views/Mint'))
const ResetPassword = lazy(() => import('./views/ResetPassword'))
const VerifyEmail = lazy(() => import('./views/VerifyEmail'))
const Roadmap = lazy(() => import('./views/Roadmap'))
const Faq = lazy(() => import('./views/Faq'))
const AboutUs = lazy(() => import('./views/AboutUs'))
const PrivacyPolicy = lazy(() => import('./views/PrivacyPolicy'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  // usePollBlockNumber()
  // useEagerConnect()
  // useUpdateNetwork()

  return (
    <Router history={history}>
      <div className="master-container">
        <Menu />
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route exact strict path="/home" component={Home} />
            {/* <Route exact strict path="/mint" component={Mint} /> */}
            <Route exact strict path="/roadmap" component={Roadmap} />
            <Route exact strict path="/faq" component={Faq} />
            <Route exact strict path="/about-us" component={AboutUs} />
            <Route exact strict path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact strict path="/reset-password/:verificationCode" component={ResetPassword} />
            <Route exact strict path="/verify-email/:verificationCode" component={VerifyEmail} />


            {/* Redirect */}
            <Redirect exact from="/" to="/home" />

            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
        <ToastListener />
        <DatePickerPortal />
      </div>
      {/* <Footer /> */}
    </Router>
  )
}

export default React.memo(App)
