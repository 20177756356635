export default {
  nftCashierContr: {
    1: '0xB4fB467D5B4Aae517acba4d47699D41AC102D89A',
    3: '',
    4: '',
    56: '0xeAf34C3644B90bdD8F9Fbfa26D5d164640df03b2',
    97: ''
  },
  nftContr: {
    1: '0xcd05E4a887629c9d715F01351B9779dEeBD20605',
    3: '',
    4: '',
    56: '0x3bA447Cf35393aA0520Af3Ac0e2175F3238D0b91',
    97: ''
  },
  multiCall: {
    1: '0x2a80799F88896E16f963284C7583f365CD645A1B',
    3: '',
    4: '0x66ab763FC0e25018BB6B693feFdE0AB31D67EB3c',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  }
}
